.hero {
  display: flex;
  padding: 8.5rem 0;

  &__text {
    width: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 4.5rem;
  }

  &__description {
    margin-bottom: 4rem;
  }

  &__image {
    width: 50%;
    flex-grow: 0;
    flex-shrink: 0;

    img {
      display: block;
      width: 100%;
      transform: translateY(-3.85rem);
    }
  }


  @include tablet() {
    .hero__text {
      padding-right: 2rem;

      br {
        display: none;
      }
    }
  }


  @include mobile {
    display: block;
    text-align: center;
    padding: 3rem 0 6rem;

    .hero__text {
      width: 100%;
      padding: 0;
      margin-bottom: 2.5rem;
    }

    .hero__description {
      margin-bottom: 2rem;
    }

    .hero__image {
      width: 300px;
      max-width: 100%;
      margin: 0 auto;

      img {
        transform: none;
      }
    }
  }
}
