.footer {
  padding: 2rem 0;
  border-top: 1px solid rgba($black, 0.1);

  &__content {
    @include contentWrapper();
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }

  &__list-item {
    padding: 0 1rem;
    color: $black;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  &__copyright {
    color: $muted;
  }

  @include tablet() {
    .footer__content {
      display: block;
    }

    .footer__list-item {
      padding: 0 1rem 0 0;
    }
  }

  @include mobile() {
    padding: 1.5rem 0 0.5rem;
    .footer__logo {
      width: 102px;
      margin-bottom: 12px;
    }

    .footer__list {
      justify-content: space-between;
    }
  }
}
