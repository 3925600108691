.services {
  padding: 3rem 0 10rem;

  &__description {
    margin-bottom: 3rem;
  }

  @include mobile() {
    padding-bottom: 4rem;

    .services__description {
      margin-bottom: 2rem;
    }
  }
}
