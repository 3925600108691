.about {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 4rem 0 10rem;

  &__image {
    width: 50%;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    order: -1;

    img {
      display: block;
      width: 100%;
      height: 100%;
      transform: translateY(-5rem);
    }
  }

  &__text {
    width: 50%;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8rem;
  }

  &__description {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 4.5rem;

    p {
      margin-top: 0;
      margin-bottom: 1.25em;
    }
  }

  @include tablet() {
    .about__text {
      padding-left: 2rem;
    }
  }

  @include mobile() {
    display: block;
    margin-top: 0;
    padding-bottom: 8rem;

    .about__image {
      width: 320px;
      max-width: 100%;
      margin: -2rem auto 0;

      img {
        transform: none;
      }
    }
    .about__text {
      width: 100%;
      padding: 0;
    }

    .about__description {
      font-size: 0.875rem;
      line-height: 1.25;
      margin-bottom: 2rem;
    }
  }
}
