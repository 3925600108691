.input {
  margin-bottom: 24px;

  &__field {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
    color: $black;
    border: 1px solid transparent;
    padding: 16px 20px;
    border-radius: 8px;
    display: block;
    width: 100%;
    background: $silver;
    resize: none;

    &::placeholder {
      color: $black;
      transition: color .25s
    }

    &:focus {
      border: 1px solid transparent;
      outline: none;

      &::placeholder {
        color: rgba($black, 0.3)
      }
    }

    &.just-validate-error-field {
      &, &:focus {
        border-color: #FF5656;
        color: #FF5656;

        &::placeholder {
          color: #FF5656;
        }
      }
    }
  }

  .just-validate-error-label {
    display: block;
    width: 100%;
    padding: 0 20px;
    border: 1px solid transparent;
    color: #FF5656 !important;
  }
}
