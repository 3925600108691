.testimonial-wrapper {
  width: calc(1008px + 8rem);
  max-width: 100%;
  padding: 0 4rem;
  margin: -6rem auto 6rem;

  @include tablet() {
    padding: 0 1.375rem;
  }

  @include mobile() {
    padding: 0 1.375rem;
    margin: -3rem auto 2rem;
  }
}

.testimonial {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0 24px 40px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  padding: 3.5rem 5rem 4.375rem;
  text-align: center;
  position: relative;

  &__text {
    font-size: 2rem;
    line-height: 1.5;
    margin-bottom: 0.5em;
    font-weight: 500;
  }

  &__caption {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  &__author {
    width: 80px;
    height: 80px;
    position: absolute;
    z-index: 100;
    right: -64px;
    bottom: 53px;

    &:before {
      content: '';
      position: absolute;
      width: 21px;
      height: 21px;
      display: block;
      top: -9px;
      left: -12px;
      background: url('../images/pointer.svg') center no-repeat;
      background-size: contain;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  @include tablet() {
    .testimonial__author {
      right: -1rem;
    }
  }

  @include mobile() {
    padding: 1.5rem 1rem;

    .testimonial__text {
      font-size: 1.25rem;
      line-height: 1.2;
    }

    .testimonial__caption {
      font-size: 1rem;
      line-height: 1.5;
      font-weight: bold;

      strong:first-child {
        display: block;
      }
    }

    .testimonial__author {
      width: 40px;
      height: 40px;
      right: -5px;
      bottom: 30px;

      &:before {
        width: 16px;
        height: 16px;
        top: -7px;
        left: -6px;
      }
    }
  }
}

.our-vision {
  width: 100%;
  background-color: #0B6B5C;
  background-image: url("../images/ov-bottom.svg"), url("../images/ov-top.svg");
  background-position: left bottom, top right;
  background-repeat: no-repeat;
  border-radius: 24px;
  padding: 5.25rem 5rem;
  color: #fff;
  text-align: center;
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 500;

  &__accent {
    color: #A7D6A4
  }

  @include mobile() {
    padding: 4rem 1rem;
    font-size: 1.25rem;
    line-height: 1.2;
    background-size: auto 3.5rem, auto 3rem;
  }
}
