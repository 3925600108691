%btnCommon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 4px;
  line-height: 20px;
  transition: color .25s, background-color .25s;

  .svg-icon {
    font-size: 1.25em;
    margin-left: 0.5em;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.btn {
  @extend %btnCommon;
  background-color: $primary;
  border: 2px solid transparent;
  color: #fff;
  padding: 16px 48px;

  &:hover, &:focus {
    background-color: $green-bright;
  }

  &:disabled {
    color: rgba(#fff, 0.5)
  }
}

.btn-secondary {
  @extend %btnCommon;
  background-color: transparent;
  border: 2px solid $secondary;
  color: $secondary;
  padding: 8px 24px;

  &:hover, &:focus {
    background-color: $secondary;
    color: #fff;
  }
}
