//Colors
$green: #4BA741;
$green-dark: #095A4D;
$green-bright: #72B62B;
$orange: #F98A45;
$black: #161F17;
$silver: #F4F5FA;

$primary: $green;
$secondary: $orange;
$muted: rgba($black, 0.5);


// Sizes
$mobile: 768px;
$tablet: 1024px;
