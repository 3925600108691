.tabs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, auto);
  grid-gap: 2rem 5.5rem;

  @include tablet() {
    grid-gap: 2rem;
  }

  @include mobile() {
    display: block;
  }
}

.tab {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  grid-column: 1;
  background-color: $silver;
  border-radius: 8px;
  transition: color .25s, background-color .25s;

  &__header {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.125;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__icon {
    display: inline-flex;
    transform: scaleY(-100%);
    transition: transform .25s;
  }

  &__description {
    display: none;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: inherit;
    opacity: 0.7;
  }

  &--active {
    color: #fff;
    background-color: $primary;

    .tab__description {
      display: block;
    }

    .tab__header {
      margin: 0 0 1rem;
    }

    .tab__icon {
      transform: scaleY(100%);
    }
  }

  @include mobile() {
    padding: 1.25rem;
    margin-bottom: 1.5rem;

    .tab__header {
      font-size: 1.25rem;
      line-height: 1.2;
    }
    .tab__description {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
}

.tab-content {
  grid-column: 2;
  display: none;

  .tab--active + & {
    display: block;
    grid-row: 1/-1;
  }

  &__list {
    padding: 0;
    list-style: none;
  }

  &__list-item {
    font-size: 1.5rem;
    line-height: 1.2em;
    font-weight: 500;
    margin: 0 0 1.6em;
    padding-left: 72px;
    position: relative;
  }

  &__item-text {
    font-size: 1.25rem;
    margin-top: 0.25em;
    color: $muted;
    font-weight: 800;
    line-height: 1.25em;
  }

  &__item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary;
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  }

  @include mobile() {
    .tab-content__list-item {
      padding-left: 56px;
      font-size: 1rem;
      line-height: 1.25;
    }
  }
}
