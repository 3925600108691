.contact {
  padding: 3rem 0 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 88px;

  &__description {
    font-size: 1.5rem;
    line-height: 2rem;
    color: $muted;
  }

  &__title {
    margin-bottom: 3rem;
  }

  &__form-inputs {
    margin-bottom: 2.5rem;
  }

  @include tablet() {
    grid-gap: 2rem;
  }

  @include mobile() {
    display: block;
    padding-bottom: 4rem;

    .contact__title {
      margin-bottom: 0.5rem;
    }

    .contact__description {
      font-size: 1rem;
      line-height: 1.5;
      margin-bottom: 2rem;
    }

    .btn {
      width: 100%;
    }
  }
}
