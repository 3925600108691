@import "../../node_modules/vanilla-cookieconsent/dist/cookieconsent.css";
@import "variables";
@import "mixins";
@import "typography";
@import "buttons";
@import "inputs";
@import "icons";
@import "sections";
@import "tabs";
@import "header";
@import "hero";
@import "testimonials";
@import "about";
@import "services";
@import "contact";
@import "footer";

html {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: $black;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #fff;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
  box-sizing: border-box;
  background-color: #fff;
  min-height: 100vh;

  * {
    &, &:after, &:before {
      box-sizing: inherit;
    }
  }
}

.contentWrapper {
  @include contentWrapper()
}

.text-page {
  padding-top: 3rem;

  .section {
    margin-bottom: 3rem;
  }

  @include mobile() {
    padding-top: 56px;
  }
}




