.header {
  height: 80px;
  background: #fff;
  box-shadow: 0 8px 32px rgba(26, 50, 28, 0.05);
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 999;

  &__link {
    display: inline-flex;
  }

  &__content {
    @include contentWrapper();
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include mobile() {
    height: 56px;
  }
}

.header-menu {
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    font-weight: 500;
    padding: 0 1.75rem;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  @include mobile() {
    display: none;
  }
}
