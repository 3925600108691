@mixin mobile() {
  @media (max-width: $mobile) {
    @content
  }
}

@mixin tablet() {
  @media (max-width: $tablet) {
    @content
  }
}

@mixin contentWrapper {
  width: 1440px;
  max-width: 100%;
  padding: 0 7rem;
  margin: 0 auto;

  @include tablet() {
    padding: 0 1.375rem;
  }
}
