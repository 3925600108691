.page-title {
  margin: 0 0 3rem;
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.125;

  @include mobile() {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
}

.section-title {
  margin: 0 0 3rem;
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 1.125;

  @include mobile() {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
}

.description {
  font-size: 1rem;
  color: $muted;

  @include mobile() {
    font-size: 0.875rem;
  }
}

.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

.text-muted {
  color: $muted;
}
