.anchored-wrapper {
  padding-top: 80px;
  margin-top: -80px;

  @include mobile() {
    padding-top: 56px;
    margin-top: -56px;
  }
}

.title__image {
  object-fit: cover;
  height: 90px;

  img {
    width: 100%;
    height: 100%;
  }
}

.section {
  width: 100%;

  &__content {
    @include contentWrapper()
  }

  &--dark {
    background-color: $silver;
  }
}
