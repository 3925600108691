:root {
  --cc-bg: #fff;
  --cc-text: #2d4156;
  --cc-border-radius: .35em;
  --cc-btn-primary-bg: #2d4156;
  --cc-btn-primary-text: var(--cc-bg);
  --cc-btn-primary-hover-bg: #1d2e38;
  --cc-btn-secondary-bg: #eaeff2;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #d8e0e6;
  --cc-btn-border-radius: 4px;
  --cc-toggle-bg-off: #919ea6;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #d5dee2;
  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #ecf2fa;
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: #f0f4f7;
  --cc-cookie-category-block-bg-hover: #e9eff4;
  --cc-section-border: #f1f3f5;
  --cc-cookie-table-border: #e9edf2;
  --cc-overlay-bg: #040608d9;
  --cc-webkit-scrollbar-bg: #cfd5db;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}

.c_darkmode {
  --cc-bg: #181b1d;
  --cc-text: #d8e5ea;
  --cc-btn-primary-bg: #a6c4dd;
  --cc-btn-primary-text: #000;
  --cc-btn-primary-hover-bg: #c2dff7;
  --cc-btn-secondary-bg: #33383c;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #3e454a;
  --cc-toggle-bg-off: #667481;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #454c54;
  --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
  --cc-toggle-knob-icon-color: var(--cc-bg);
  --cc-block-text: #b3bfc5;
  --cc-cookie-category-block-bg: #23272a;
  --cc-cookie-category-block-bg-hover: #2b3035;
  --cc-section-border: #292d31;
  --cc-cookie-table-border: #2b3035;
  --cc-webkit-scrollbar-bg: #667481;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}

.cc_div *, .cc_div :after, .cc_div :before, .cc_div :hover {
  border-radius: unset;
  box-shadow: none;
  box-sizing: border-box;
  color: inherit;
  float: none;
  font-family: inherit;
  font-size: 1em;
  font-style: inherit;
  font-variant: normal;
  font-weight: inherit;
  height: auto;
  letter-spacing: unset;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  visibility: unset;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  line-height: 1.2;
  text-decoration: none;
  transition: none;
  animation: none;
}

.cc_div {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2d4156;
  color: var(--cc-text);
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
  font-weight: 400;
}

#c-ttl, #s-bl td:before, #s-ttl, .cc_div .b-tl, .cc_div .c-bn {
  font-weight: 600;
}

#cm, #s-bl .act .b-acc, #s-inr, .cc_div .b-tl, .cc_div .c-bl {
  border-radius: .35em;
  border-radius: var(--cc-border-radius);
}

#s-bl .act .b-acc {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.cc_div a, .cc_div button, .cc_div input {
  appearance: none;
  overflow: hidden;
}

.cc_div a {
  border-bottom: 1px solid;
}

.cc_div a:hover {
  border-color: #0000;
  text-decoration: none;
}

#cm-ov, #cs-ov, .c--anim #cm, .c--anim #s-cnt, .c--anim #s-inr {
  transition: visibility .25s linear, opacity .25s, transform .25s !important;
}

.c--anim .c-bn {
  transition: background-color .25s !important;
}

.c--anim #cm.bar.slide, .c--anim #cm.bar.slide + #cm-ov, .c--anim .bar.slide #s-inr, .c--anim .bar.slide + #cs-ov {
  transition: visibility .4s, opacity .4s, transform .4s !important;
}

#cm.bar.slide, .cc_div .bar.slide #s-inr {
  opacity: 1;
  transform: translateX(100%);
}

#cm.bar.top.slide, .cc_div .bar.left.slide #s-inr {
  opacity: 1;
  transform: translateX(-100%);
}

#cm.slide, .cc_div .slide #s-inr {
  transform: translateY(1.6em);
}

#cm.top.slide {
  transform: translateY(-1.6em);
}

#cm.bar.slide {
  transform: translateY(100%);
}

#cm.bar.top.slide {
  transform: translateY(-100%);
}

.show--consent .c--anim #cm, .show--consent .c--anim #cm.bar, .show--settings .c--anim #s-inr, .show--settings .c--anim .bar.slide #s-inr {
  opacity: 1;
  transform: scale(1);
  visibility: visible !important;
}

.show--consent .c--anim #cm.box.middle, .show--consent .c--anim #cm.cloud.middle {
  transform: scale(1)translateY(-50%);
}

.show--settings .c--anim #s-cnt {
  visibility: visible !important;
}

.force--consent.show--consent .c--anim #cm-ov, .show--settings .c--anim #cs-ov {
  opacity: 1 !important;
  visibility: visible !important;
}

#cm {
  background: #fff;
  background: var(--cc-bg);
  max-width: 24.2em;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  z-index: 1;
  padding: 1.1em 1.5em 1.4em;
  font-family: inherit;
  line-height: normal;
  position: fixed;
  bottom: 1.25em;
  right: 1.25em;
  transform: scale(.95);
  box-shadow: 0 .625em 1.875em #02020347;
}

#cc_div #cm {
  display: block !important;
}

#c-ttl {
  margin-bottom: .7em;
  font-size: 1.05em;
}

.cloud #c-ttl {
  margin-top: -.15em;
}

#c-txt {
  font-size: .9em;
  line-height: 1.5em;
}

.cc_div #c-bns {
  justify-content: space-between;
  margin-top: 1.4em;
  display: flex;
}

.cc_div .c-bn {
  background: #eaeff2;
  background: var(--cc-btn-secondary-bg);
  border-radius: 4px;
  border-radius: var(--cc-btn-border-radius);
  color: #2d4156;
  color: var(--cc-btn-secondary-text);
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  flex: 1;
  padding: 1em 1.7em;
  font-size: .82em;
  display: inline-block;
}

#c-bns button + button, #s-c-bn, #s-cnt button + button {
  float: right;
  margin-left: 1em;
}

#s-cnt #s-rall-bn {
  float: none;
}

#cm .c_link:active, #cm .c_link:hover, #s-c-bn:active, #s-c-bn:hover, #s-cnt button + button:active, #s-cnt button + button:hover {
  background: #d8e0e6;
  background: var(--cc-btn-secondary-hover-bg);
}

#s-cnt {
  height: 100%;
  visibility: hidden;
  width: 100%;
  z-index: 101;
  display: table;
  position: fixed;
  top: 0;
  left: 0;
}

#s-bl {
  outline: none;
}

#s-bl .title {
  margin-top: 1.4em;
}

#s-bl .b-bn, #s-bl .title:first-child {
  margin-top: 0;
}

#s-bl .b-acc .p {
  margin-top: 0;
  padding: 1em;
}

#s-cnt .b-bn .b-tl {
  width: 100%;
  background: none;
  padding: 1.3em 6.4em 1.3em 2.7em;
  font-family: inherit;
  font-size: .95em;
  transition: background-color .25s;
  display: block;
  position: relative;
}

#s-cnt .b-bn .b-tl.exp {
  cursor: pointer;
}

#s-cnt .act .b-bn .b-tl {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

#s-cnt .b-bn .b-tl:active, #s-cnt .b-bn .b-tl:hover {
  background: #e9eff4;
  background: var(--cc-cookie-category-block-bg-hover);
}

#s-bl .b-bn {
  position: relative;
}

#s-bl .c-bl {
  border: 1px solid #f1f3f5;
  border: 1px solid var(--cc-section-border);
  margin-bottom: .5em;
  padding: 1em;
  transition: background-color .25s;
}

#s-bl .c-bl:hover {
  background: #f0f4f7;
  background: var(--cc-cookie-category-block-bg);
}

#s-bl .c-bl:last-child {
  margin-bottom: .5em;
}

#s-bl .c-bl:first-child {
  border: none;
  margin-top: 0;
  margin-bottom: 2em;
  padding: 0;
  transition: none;
}

#s-bl .c-bl:not(.b-ex):first-child:hover {
  background: none;
  background: unset;
}

#s-bl .c-bl.b-ex {
  background: #f0f4f7;
  background: var(--cc-cookie-category-block-bg);
  border: none;
  padding: 0;
  transition: none;
}

#s-bl .c-bl.b-ex + .c-bl {
  margin-top: 2em;
}

#s-bl .c-bl.b-ex + .c-bl.b-ex {
  margin-top: 0;
}

#s-bl .c-bl.b-ex:first-child {
  margin-bottom: .5em;
}

#s-bl .b-acc {
  max-height: 0;
  margin-bottom: 0;
  padding-top: 0;
  display: none;
  overflow: hidden;
}

#s-bl .act .b-acc {
  max-height: 100%;
  display: block;
  overflow: hidden;
}

#s-cnt .p {
  color: #2d4156;
  color: var(--cc-block-text);
  margin-top: .85em;
  font-size: .9em;
  line-height: 1.5em;
}

.cc_div .b-tg .c-tgl:disabled {
  cursor: not-allowed;
}

#c-vln {
  vertical-align: middle;
  display: table-cell;
  position: relative;
}

#cs {
  width: 100%;
  padding: 0 1.7em;
  position: fixed;
  inset: 0;
}

#cs, #s-inr {
  height: 100%;
}

#s-inr {
  max-width: 45em;
  opacity: 0;
  visibility: hidden;
  margin: 0 auto;
  padding-top: 4.75em;
  padding-bottom: 4.75em;
  position: relative;
  overflow: hidden;
  transform: scale(.96);
  box-shadow: 0 13px 27px -5px #03060942;
}

#s-bns, #s-hdr, #s-inr {
  background: #fff;
  background: var(--cc-bg);
}

#s-bl {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
  width: 100%;
  padding: 1.3em 1.8em;
  display: block;
}

#s-bns {
  border-top: 1px solid #f1f3f5;
  border-top: 1px solid var(--cc-section-border);
  height: 4.75em;
  padding: 1em 1.8em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.cc_div .cc-link {
  border-bottom: 1px solid #2d4156;
  border-bottom: 1px solid var(--cc-btn-primary-bg);
  color: #2d4156;
  color: var(--cc-btn-primary-bg);
  cursor: pointer;
  padding-bottom: 0;
  font-weight: 600;
  text-decoration: none;
  display: inline;
}

.cc_div .cc-link:active, .cc_div .cc-link:hover {
  border-color: #0000;
}

#c-bns button:first-child, #s-bns button:first-child {
  background: #2d4156;
  background: var(--cc-btn-primary-bg);
  color: #fff;
  color: var(--cc-btn-primary-text);
}

#c-bns.swap button:first-child {
  background: #eaeff2;
  background: var(--cc-btn-secondary-bg);
  color: #2d4156;
  color: var(--cc-btn-secondary-text);
}

#c-bns.swap button:last-child {
  background: #2d4156;
  background: var(--cc-btn-primary-bg);
  color: #fff;
  color: var(--cc-btn-primary-text);
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg {
  background: #2d4156;
  background: var(--cc-toggle-bg-on);
}

#c-bns button:first-child:active, #c-bns button:first-child:hover, #c-bns.swap button:last-child:active, #c-bns.swap button:last-child:hover, #s-bns button:first-child:active, #s-bns button:first-child:hover {
  background: #1d2e38;
  background: var(--cc-btn-primary-hover-bg);
}

#c-bns.swap button:first-child:active, #c-bns.swap button:first-child:hover {
  background: #d8e0e6;
  background: var(--cc-btn-secondary-hover-bg);
}

#s-hdr {
  border-bottom: 1px solid #f1f3f5;
  border-bottom: 1px solid var(--cc-section-border);
  height: 4.75em;
  width: 100%;
  z-index: 2;
  padding: 0 1.8em;
  display: table;
  position: absolute;
  top: 0;
}

#s-hdr, #s-ttl {
  vertical-align: middle;
}

#s-ttl {
  font-size: 1em;
  display: table-cell;
}

#s-c-bn {
  height: 1.7em;
  width: 1.7em;
  margin: 0;
  padding: 0;
  font-size: 1.45em;
  font-weight: 400;
  position: relative;
  overflow: hidden;
}

#s-c-bnc {
  vertical-align: middle;
  display: table-cell;
}

.cc_div span.t-lb {
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  position: absolute;
  top: 0;
  overflow: hidden;
}

#c_policy__text {
  height: 31.25em;
  margin-top: 1.25em;
  overflow-y: auto;
}

#c-s-in {
  height: 100%;
  height: calc(100% - 2.5em);
  max-height: 37.5em;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (min-width: 688px) {
  #s-bl::-webkit-scrollbar {
    height: 100%;
    width: .9em;
    background: none;
    border-radius: 0 .25em .25em 0;
  }

  #s-bl::-webkit-scrollbar-thumb {
    background: #cfd5db;
    background: var(--cc-webkit-scrollbar-bg);
    border: .25em solid #fff;
    border: .25em solid var(--cc-bg);
    border-radius: 100em;
  }

  #s-bl::-webkit-scrollbar-thumb:hover {
    background: #9199a0;
    background: var(--cc-webkit-scrollbar-bg-hover);
  }

  #s-bl::-webkit-scrollbar-button {
    height: 5px;
    width: 10px;
  }
}

.cc_div .b-tg {
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  margin: auto;
  display: inline-block;
  bottom: 0;
  right: 1.2em;
}

.cc_div .b-tg, .cc_div .b-tg .c-tgl {
  cursor: pointer;
  position: absolute;
  top: 0;
}

.cc_div .b-tg .c-tgl {
  border: 0;
  margin: 0;
  display: block;
  left: 0;
}

.cc_div .b-tg .c-tg {
  background: #919ea6;
  background: var(--cc-toggle-bg-off);
  pointer-events: none;
  transition: background-color .25s, box-shadow .25s;
  position: absolute;
}

.cc_div .b-tg, .cc_div .b-tg .c-tg, .cc_div .b-tg .c-tgl, .cc_div span.t-lb {
  height: 1.5em;
  width: 3.4em;
  border-radius: 4em;
}

.cc_div .b-tg .c-tg.c-ro {
  cursor: not-allowed;
}

.cc_div .b-tg .c-tgl ~ .c-tg.c-ro {
  background: #d5dee2;
  background: var(--cc-toggle-bg-readonly);
}

.cc_div .b-tg .c-tgl ~ .c-tg.c-ro:after {
  box-shadow: none;
}

.cc_div .b-tg .c-tg:after {
  background: #fff;
  background: var(--cc-toggle-knob-bg);
  box-sizing: content-box;
  content: "";
  height: 1.25em;
  width: 1.25em;
  border: none;
  border-radius: 100%;
  transition: transform .25s;
  display: block;
  position: relative;
  top: .125em;
  left: .125em;
  box-shadow: 0 1px 2px #1820235c;
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg:after {
  transform: translateX(1.9em);
}

#s-bl table, #s-bl td, #s-bl th {
  border: none;
}

#s-bl tbody tr {
  transition: background-color .25s;
}

#s-bl tbody tr:hover {
  background: #e9eff4;
  background: var(--cc-cookie-category-block-bg-hover);
}

#s-bl table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#s-bl td, #s-bl th {
  text-align: left;
  vertical-align: top;
  padding: .8em .625em .8em 1.2em;
  font-size: .8em;
}

#s-bl th {
  padding: 1.2em;
  font-family: inherit;
}

#s-bl thead tr:first-child {
  border-bottom: 1px solid #e9edf2;
  border-bottom: 1px solid var(--cc-cookie-table-border);
}

.force--consent #cs, .force--consent #s-cnt {
  width: 100vw;
}

#cm-ov, #cs-ov {
  background: #040608d9;
  background: var(--cc-overlay-bg);
  opacity: 0;
  visibility: hidden;
  transition: none;
  display: none;
  position: fixed;
  inset: 0;
}

.c--anim #cs-ov, .force--consent .c--anim #cm-ov, .force--consent.show--consent #cm-ov, .show--settings #cs-ov {
  display: block;
}

#cs-ov {
  z-index: 2;
}

.force--consent .cc_div {
  visibility: hidden;
  width: 100%;
  width: 100vw;
  transition: visibility .25s linear;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

.force--consent.show--consent .c--anim .cc_div, .force--consent.show--settings .c--anim .cc_div {
  visibility: visible;
}

.force--consent #cm {
  position: absolute;
}

.force--consent #cm.bar {
  max-width: 100vw;
  width: 100vw;
}

html.force--consent.show--consent {
  overflow-y: hidden !important;
}

html.force--consent.show--consent, html.force--consent.show--consent body {
  height: auto !important;
  overflow-x: hidden !important;
}

.cc_div .act .b-bn .exp:before, .cc_div .b-bn .exp:before {
  border: solid #2d4156;
  border: solid var(--cc-btn-secondary-text);
  content: "";
  border-width: 0 2px 2px 0;
  margin-right: 15px;
  padding: .2em;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 1.2em;
  transform: translateY(-50%)rotate(45deg);
}

.cc_div .act .b-bn .b-tl:before {
  transform: translateY(-20%)rotate(225deg);
}

.cc_div .on-i:before {
  border: solid #ecf2fa;
  border: solid var(--cc-toggle-knob-icon-color);
  border-width: 0 2px 2px 0;
  padding: .1em .1em .45em;
  display: inline-block;
  top: .37em;
  left: .75em;
}

#s-c-bn:after, #s-c-bn:before, .cc_div .on-i:before {
  content: "";
  margin: 0 auto;
  position: absolute;
  transform: rotate(45deg);
}

#s-c-bn:after, #s-c-bn:before {
  background: #2d4156;
  background: var(--cc-btn-secondary-text);
  height: .6em;
  width: 1.5px;
  border-radius: 1em;
  top: .58em;
  left: .82em;
}

#s-c-bn:after {
  transform: rotate(-45deg);
}

.cc_div .off-i, .cc_div .on-i {
  height: 100%;
  text-align: center;
  width: 50%;
  transition: opacity .15s;
  display: block;
  position: absolute;
  right: 0;
}

.cc_div .on-i {
  opacity: 0;
  left: 0;
}

.cc_div .off-i:after, .cc_div .off-i:before {
  background: #ecf2fa;
  background: var(--cc-toggle-knob-icon-color);
  content: " ";
  height: .7em;
  transform-origin: center;
  width: .09375em;
  margin: 0 auto;
  display: block;
  position: absolute;
  top: .42em;
  right: .8em;
}

.cc_div .off-i:before {
  transform: rotate(45deg);
}

.cc_div .off-i:after {
  transform: rotate(-45deg);
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg .on-i {
  opacity: 1;
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg .off-i {
  opacity: 0;
}

#cm.box.middle, #cm.cloud.middle {
  top: 50%;
  bottom: auto;
  transform: translateY(-37%);
}

#cm.box.middle.zoom, #cm.cloud.middle.zoom {
  transform: scale(.95)translateY(-50%);
}

#cm.box.center, #cm.cloud {
  margin: 0 auto;
  left: 1em;
  right: 1em;
}

#cm.cloud {
  max-width: 50em;
  text-align: center;
  width: unset;
  padding: 1.3em 2em;
  overflow: hidden;
}

.cc_div .cloud #c-inr {
  width: 100%;
  display: table;
}

.cc_div .cloud #c-inr-i {
  vertical-align: top;
  width: 70%;
  padding-right: 2.4em;
  display: table-cell;
}

.cc_div .cloud #c-txt {
  font-size: .85em;
}

.cc_div .cloud #c-bns {
  min-width: 170px;
  vertical-align: middle;
  display: table-cell;
}

#cm.cloud .c-bn {
  width: 100%;
  margin: .625em 0 0;
}

#cm.cloud .c-bn:first-child {
  margin: 0;
}

#cm.cloud.left {
  margin-right: 1.25em;
}

#cm.cloud.right {
  margin-left: 1.25em;
}

#cm.bar {
  max-width: 100%;
  width: 100%;
  border-radius: 0;
  padding: 2em;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

#cm.bar #c-inr {
  max-width: 32em;
  margin: 0 auto;
}

#cm.bar #c-bns {
  max-width: 33.75em;
}

#cm.bar #cs {
  padding: 0;
}

.cc_div .bar #c-s-in {
  height: 100%;
  max-height: 100%;
  top: 0;
  transform: none;
}

.cc_div .bar #s-bl, .cc_div .bar #s-bns, .cc_div .bar #s-hdr {
  padding-left: 1.6em;
  padding-right: 1.6em;
}

.cc_div .bar #cs {
  padding: 0;
}

.cc_div .bar #s-inr {
  max-width: 32em;
  border-radius: 0;
  margin: 0 0 0 auto;
}

.cc_div .bar.left #s-inr {
  margin-left: 0;
  margin-right: auto;
}

.cc_div .bar #s-bl table, .cc_div .bar #s-bl tbody, .cc_div .bar #s-bl td, .cc_div .bar #s-bl th, .cc_div .bar #s-bl thead, .cc_div .bar #s-bl tr, .cc_div .bar #s-cnt {
  display: block;
}

.cc_div .bar #s-bl thead tr {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.cc_div .bar #s-bl tr {
  border-top: 1px solid #e9edf2;
  border-top: 1px solid var(--cc-cookie-table-border);
}

.cc_div .bar #s-bl td {
  border: none;
  padding-left: 35%;
  position: relative;
}

.cc_div .bar #s-bl td:before {
  color: #2d4156;
  color: var(--cc-text);
  content: attr(data-column);
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: .625em;
  position: absolute;
  left: 1em;
  overflow: hidden;
}

#cm.top {
  top: 1.25em;
  bottom: auto;
}

#cm.left {
  left: 1.25em;
  right: auto;
}

#cm.right {
  left: auto;
  right: 1.25em;
}

#cm.bar.left, #cm.bar.right {
  left: 0;
  right: 0;
}

#cm.bar.top {
  top: 0;
}

@media screen and (max-width: 688px) {
  #cm, #cm.cloud, #cm.left, #cm.right {
    max-width: 100%;
    width: auto;
    margin: 0;
    display: block;
    bottom: 1em;
    left: 1em;
    right: 1em;
    padding: 1.4em !important;
  }

  .force--consent #cm, .force--consent #cm.cloud {
    max-width: 100vw;
    width: auto;
  }

  #cm.top {
    top: 1em;
    bottom: auto;
  }

  #cm.bottom {
    top: auto;
    bottom: 1em;
  }

  #cm.bar.bottom {
    bottom: 0;
  }

  #cm.cloud .c-bn {
    font-size: .85em;
  }

  #s-bns, .cc_div .bar #s-bns {
    padding: 1em 1.3em;
  }

  .cc_div .bar #s-inr {
    max-width: 100%;
    width: 100%;
  }

  .cc_div .cloud #c-inr-i {
    padding-right: 0;
  }

  #cs {
    border-radius: 0;
    padding: 0;
  }

  #c-s-in {
    height: 100%;
    max-height: 100%;
    top: 0;
    transform: none;
  }

  .cc_div .b-tg {
    right: 1.1em;
    transform: scale(1.1);
  }

  #s-inr {
    border-radius: 0;
    margin: 0;
    padding-bottom: 7.9em;
  }

  #s-bns {
    height: 7.9em;
  }

  #s-bl, .cc_div .bar #s-bl {
    padding: 1.3em;
  }

  #s-hdr, .cc_div .bar #s-hdr {
    padding: 0 1.3em;
  }

  #s-bl table {
    width: 100%;
  }

  #s-inr.bns-t {
    padding-bottom: 10.5em;
  }

  .bns-t #s-bns {
    height: 10.5em;
  }

  .cc_div .bns-t .c-bn {
    padding: .9em 1.6em;
    font-size: .83em;
  }

  #s-cnt .b-bn .b-tl {
    padding-top: 1.2em;
    padding-bottom: 1.2em;
  }

  #s-bl table, #s-bl tbody, #s-bl td, #s-bl th, #s-bl thead, #s-bl tr, #s-cnt {
    display: block;
  }

  #s-bl thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #s-bl tr {
    border-top: 1px solid #e9edf2;
    border-top: 1px solid var(--cc-cookie-table-border);
  }

  #s-bl td {
    border: none;
    padding-left: 35%;
    position: relative;
  }

  #s-bl td:before {
    color: #2d4156;
    color: var(--cc-text);
    content: attr(data-column);
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: .625em;
    position: absolute;
    left: 1em;
    overflow: hidden;
  }

  #cm .c-bn, .cc_div .c-bn {
    width: 100%;
    margin-right: 0;
  }

  #s-cnt #s-rall-bn {
    margin-left: 0;
  }

  .cc_div #c-bns {
    flex-direction: column;
  }

  #c-bns button + button, #s-cnt button + button {
    float: unset;
    margin-top: .625em;
    margin-left: 0;
  }

  #cm.box, #cm.cloud {
    width: auto;
    left: 1em;
    right: 1em;
  }

  #cm.cloud.left, #cm.cloud.right {
    margin: 0;
  }

  .cc_div .cloud #c-bns, .cc_div .cloud #c-inr, .cc_div .cloud #c-inr-i {
    min-width: unset;
    width: auto;
    display: block;
  }

  .cc_div .cloud #c-txt {
    font-size: .9em;
  }

  .cc_div .cloud #c-bns {
    margin-top: 1.625em;
  }
}

.cc_div.ie #c-vln {
  height: 100%;
  padding-top: 5.62em;
}

.cc_div.ie .bar #c-vln {
  padding-top: 0;
}

.cc_div.ie #cs {
  max-height: 37.5em;
  margin-top: -5.625em;
  position: relative;
  top: 0;
}

.cc_div.ie .bar #cs {
  max-height: 100%;
  margin-top: 0;
}

.cc_div.ie #cm {
  border: 1px solid #dee6e9;
}

.cc_div.ie #c-s-in {
  top: 0;
}

.cc_div.ie .b-tg {
  margin-bottom: .7em;
  padding-left: 1em;
}

.cc_div.ie .b-tg .c-tgl:checked ~ .c-tg:after {
  left: 1.95em;
}

.cc_div.ie #s-bl table {
  overflow: auto;
}

.cc_div.ie .b-tg .c-tg {
  display: none;
}

.cc_div.ie .b-tg .c-tgl {
  height: auto;
  vertical-align: middle;
  margin-bottom: .2em;
  display: inline-block;
  position: relative;
}

.cc_div.ie #s-cnt .b-bn .b-tl {
  padding: 1.3em 6.4em 1.3em 1.4em;
}

.cc_div.ie .bar #s-bl td:before {
  display: none;
}

.cc_div.ie .bar #s-bl td {
  padding: .8em .625em .8em 1.2em;
}

.cc_div.ie .bar #s-bl thead tr {
  position: relative;
}

.cc_div.ie .b-tg .t-lb {
  filter: alpha(opacity= 0);
}

.cc_div.ie #cm-ov, .cc_div.ie #cs-ov {
  filter: alpha(opacity= 80);
}

.page-title {
  margin: 0 0 3rem;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.125;
}

@media (max-width: 768px) {
  .page-title {
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
}

.section-title {
  margin: 0 0 3rem;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.125;
}

@media (max-width: 768px) {
  .section-title {
    margin-bottom: .5rem;
    font-size: 2rem;
  }
}

.description {
  color: #161f1780;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .description {
    font-size: .875rem;
  }
}

.text-primary {
  color: #4ba741;
}

.text-secondary {
  color: #f98a45;
}

.text-muted {
  color: #161f1780;
}

.btn-secondary, .btn {
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  transition: color .25s, background-color .25s;
  display: inline-flex;
}

.btn-secondary .svg-icon, .btn .svg-icon {
  margin-left: .5em;
  font-size: 1.25em;
}

.btn-secondary:disabled, .btn:disabled {
  cursor: not-allowed;
}

.btn {
  color: #fff;
  background-color: #4ba741;
  border: 2px solid #0000;
  padding: 16px 48px;
}

.btn:hover, .btn:focus {
  background-color: #72b62b;
}

.btn:disabled {
  color: #ffffff80;
}

.btn-secondary {
  color: #f98a45;
  background-color: #0000;
  border: 2px solid #f98a45;
  padding: 8px 24px;
}

.btn-secondary:hover, .btn-secondary:focus {
  color: #fff;
  background-color: #f98a45;
}

.input {
  margin-bottom: 24px;
}

.input__field {
  color: #161f17;
  width: 100%;
  resize: none;
  background: #f4f5fa;
  border: 1px solid #0000;
  border-radius: 8px;
  padding: 16px 20px;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  display: block;
}

.input__field::placeholder {
  color: #161f17;
  transition: color .25s;
}

.input__field:focus {
  border: 1px solid #0000;
  outline: none;
}

.input__field:focus::placeholder {
  color: #161f174d;
}

.input__field.just-validate-error-field, .input__field.just-validate-error-field:focus {
  color: #ff5656;
  border-color: #ff5656;
}

.input__field.just-validate-error-field::placeholder, .input__field.just-validate-error-field:focus::placeholder {
  color: #ff5656;
}

.input .just-validate-error-label {
  width: 100%;
  border: 1px solid #0000;
  padding: 0 20px;
  display: block;
  color: #ff5656 !important;
}

.svg-icon {
  width: 1em;
  height: 1em;
  color: inherit;
  fill: currentColor;
  stroke: currentColor;
}

.anchored-wrapper {
  margin-top: -80px;
  padding-top: 80px;
}

@media (max-width: 768px) {
  .anchored-wrapper {
    margin-top: -56px;
    padding-top: 56px;
  }
}

.title__image {
  object-fit: cover;
  height: 90px;
}

.title__image img {
  width: 100%;
  height: 100%;
}

.section {
  width: 100%;
}

.section__content {
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 7rem;
}

@media (max-width: 1024px) {
  .section__content {
    padding: 0 1.375rem;
  }
}

.section--dark {
  background-color: #f4f5fa;
}

.tabs {
  grid-gap: 2rem 5.5rem;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media (max-width: 1024px) {
  .tabs {
    grid-gap: 2rem;
  }
}

@media (max-width: 768px) {
  .tabs {
    display: block;
  }
}

.tab {
  width: 100%;
  background-color: #f4f5fa;
  border-radius: 8px;
  flex-direction: column;
  grid-column: 1;
  justify-content: center;
  padding: 2rem;
  transition: color .25s, background-color .25s;
  display: flex;
}

.tab__header {
  justify-content: space-between;
  align-items: center;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.125;
  display: flex;
}

.tab__icon {
  transition: transform .25s;
  display: inline-flex;
  transform: scaleY(-100%);
}

.tab__description {
  color: inherit;
  opacity: .7;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: none;
}

.tab--active {
  color: #fff;
  background-color: #4ba741;
}

.tab--active .tab__description {
  display: block;
}

.tab--active .tab__header {
  margin: 0 0 1rem;
}

.tab--active .tab__icon {
  transform: scaleY(100%);
}

@media (max-width: 768px) {
  .tab {
    margin-bottom: 1.5rem;
    padding: 1.25rem;
  }

  .tab .tab__header {
    font-size: 1.25rem;
    line-height: 1.2;
  }

  .tab .tab__description {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.tab-content {
  grid-column: 2;
  display: none;
}

.tab--active + .tab-content {
  grid-row: 1 / -1;
  display: block;
}

.tab-content__list {
  padding: 0;
  list-style: none;
}

.tab-content__list-item {
  margin: 0 0 1.6em;
  padding-left: 72px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2em;
  position: relative;
}

.tab-content__item-text {
  color: #161f1780;
  margin-top: .25em;
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 1.25em;
}

.tab-content__item-icon {
  color: #4ba741;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 4px 40px #00000040;
}

@media (max-width: 768px) {
  .tab-content .tab-content__list-item {
    padding-left: 56px;
    font-size: 1rem;
    line-height: 1.25;
  }
}

.header {
  height: 80px;
  width: 100%;
  z-index: 999;
  background: #fff;
  align-items: center;
  display: flex;
  position: sticky;
  top: 0;
  box-shadow: 0 8px 32px #1a321c0d;
}

.header__link {
  display: inline-flex;
}

.header__content {
  width: 1440px;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 7rem;
  display: flex;
}

@media (max-width: 1024px) {
  .header__content {
    padding: 0 1.375rem;
  }
}

@media (max-width: 768px) {
  .header {
    height: 56px;
  }
}

.header-menu__list {
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.header-menu__item {
  padding: 0 1.75rem;
  font-weight: 500;
}

.header-menu__item a {
  color: inherit;
  text-decoration: none;
}

@media (max-width: 768px) {
  .header-menu {
    display: none;
  }
}

.hero {
  padding: 8.5rem 0;
  display: flex;
}

.hero__text {
  width: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 4.5rem;
}

.hero__description {
  margin-bottom: 4rem;
}

.hero__image {
  width: 50%;
  flex-grow: 0;
  flex-shrink: 0;
}

.hero__image img {
  width: 100%;
  display: block;
  transform: translateY(-3.85rem);
}

@media (max-width: 1024px) {
  .hero .hero__text {
    padding-right: 2rem;
  }

  .hero .hero__text br {
    display: none;
  }
}

@media (max-width: 768px) {
  .hero {
    text-align: center;
    padding: 3rem 0 6rem;
    display: block;
  }

  .hero .hero__text {
    width: 100%;
    margin-bottom: 2.5rem;
    padding: 0;
  }

  .hero .hero__description {
    margin-bottom: 2rem;
  }

  .hero .hero__image {
    width: 300px;
    max-width: 100%;
    margin: 0 auto;
  }

  .hero .hero__image img {
    transform: none;
  }
}

.testimonial-wrapper {
  width: calc(1008px + 8rem);
  max-width: 100%;
  margin: -6rem auto 6rem;
  padding: 0 4rem;
}

@media (max-width: 1024px) {
  .testimonial-wrapper {
    padding: 0 1.375rem;
  }
}

@media (max-width: 768px) {
  .testimonial-wrapper {
    margin: -3rem auto 2rem;
    padding: 0 1.375rem;
  }
}

.testimonial {
  width: 100%;
  text-align: center;
  background: #fff;
  border-radius: 24px;
  padding: 3.5rem 5rem 4.375rem;
  position: relative;
  box-shadow: 0 24px 40px #0000000d;
}

.testimonial__text {
  margin-bottom: .5em;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.5;
}

.testimonial__caption {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.testimonial__author {
  width: 80px;
  height: 80px;
  z-index: 100;
  position: absolute;
  bottom: 53px;
  right: -64px;
}

.testimonial__author:before {
  content: "";
  width: 21px;
  height: 21px;
  background: url("pointer.5090c69b.svg") center / contain no-repeat;
  display: block;
  position: absolute;
  top: -9px;
  left: -12px;
}

.testimonial__author img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .testimonial .testimonial__author {
    right: -1rem;
  }
}

@media (max-width: 768px) {
  .testimonial {
    padding: 1.5rem 1rem;
  }

  .testimonial .testimonial__text {
    font-size: 1.25rem;
    line-height: 1.2;
  }

  .testimonial .testimonial__caption {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
  }

  .testimonial .testimonial__caption strong:first-child {
    display: block;
  }

  .testimonial .testimonial__author {
    width: 40px;
    height: 40px;
    bottom: 30px;
    right: -5px;
  }

  .testimonial .testimonial__author:before {
    width: 16px;
    height: 16px;
    top: -7px;
    left: -6px;
  }
}

.our-vision {
  width: 100%;
  color: #fff;
  text-align: center;
  background-color: #0b6b5c;
  background-image: url("ov-bottom.a512ebe8.svg"), url("ov-top.9f594905.svg");
  background-position: 0 100%, 100% 0;
  background-repeat: no-repeat;
  border-radius: 24px;
  padding: 5.25rem 5rem;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.5;
}

.our-vision__accent {
  color: #a7d6a4;
}

@media (max-width: 768px) {
  .our-vision {
    background-size: auto 3.5rem, auto 3rem;
    padding: 4rem 1rem;
    font-size: 1.25rem;
    line-height: 1.2;
  }
}

.about {
  width: 100%;
  align-items: flex-start;
  padding: 4rem 0 10rem;
  display: flex;
}

.about__image {
  width: 50%;
  flex: 0 0 50%;
  order: -1;
}

.about__image img {
  width: 100%;
  height: 100%;
  display: block;
  transform: translateY(-5rem);
}

.about__text {
  width: 50%;
  flex: 0 0 50%;
  padding-left: 8rem;
}

.about__description {
  margin-bottom: 4.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.about__description p {
  margin-top: 0;
  margin-bottom: 1.25em;
}

@media (max-width: 1024px) {
  .about .about__text {
    padding-left: 2rem;
  }
}

@media (max-width: 768px) {
  .about {
    margin-top: 0;
    padding-bottom: 8rem;
    display: block;
  }

  .about .about__image {
    width: 320px;
    max-width: 100%;
    margin: -2rem auto 0;
  }

  .about .about__image img {
    transform: none;
  }

  .about .about__text {
    width: 100%;
    padding: 0;
  }

  .about .about__description {
    margin-bottom: 2rem;
    font-size: .875rem;
    line-height: 1.25;
  }
}

.services {
  padding: 3rem 0 10rem;
}

.services__description {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .services {
    padding-bottom: 4rem;
  }

  .services .services__description {
    margin-bottom: 2rem;
  }
}

.contact {
  grid-gap: 88px;
  grid-template-columns: 1fr 1fr;
  padding: 3rem 0 5rem;
  display: grid;
}

.contact__description {
  color: #161f1780;
  font-size: 1.5rem;
  line-height: 2rem;
}

.contact__title {
  margin-bottom: 3rem;
}

.contact__form-inputs {
  margin-bottom: 2.5rem;
}

@media (max-width: 1024px) {
  .contact {
    grid-gap: 2rem;
  }
}

@media (max-width: 768px) {
  .contact {
    padding-bottom: 4rem;
    display: block;
  }

  .contact .contact__title {
    margin-bottom: .5rem;
  }

  .contact .contact__description {
    margin-bottom: 2rem;
    font-size: 1rem;
    line-height: 1.5;
  }

  .contact .btn {
    width: 100%;
  }
}

.footer {
  border-top: 1px solid #161f171a;
  padding: 2rem 0;
}

.footer__content {
  width: 1440px;
  max-width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0 7rem;
  display: flex;
}

@media (max-width: 1024px) {
  .footer__content {
    padding: 0 1.375rem;
  }
}

.footer__list {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.footer__list-item {
  color: #161f17;
  padding: 0 1rem;
}

.footer__list-item a {
  color: inherit;
  text-decoration: none;
}

.footer__copyright {
  color: #161f1780;
}

@media (max-width: 1024px) {
  .footer .footer__content {
    display: block;
  }

  .footer .footer__list-item {
    padding: 0 1rem 0 0;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 1.5rem 0 .5rem;
  }

  .footer .footer__logo {
    width: 102px;
    margin-bottom: 12px;
  }

  .footer .footer__list {
    justify-content: space-between;
  }
}

html {
  color: #161f17;
  width: 100%;
  scroll-behavior: smooth;
  background-color: #fff;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
}

body {
  width: 100%;
  box-sizing: border-box;
  min-height: 100vh;
  background-color: #fff;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
}

body *, body :after, body :before {
  box-sizing: inherit;
}

.contentWrapper {
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 7rem;
}

@media (max-width: 1024px) {
  .contentWrapper {
    padding: 0 1.375rem;
  }
}

.text-page {
  padding-top: 3rem;
}

.text-page .section {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .text-page {
    padding-top: 56px;
  }
}

/*# sourceMappingURL=index.d23be4b6.css.map */
